import React from 'react'
import { CssBaseline, MuiThemeProvider, createMuiTheme } from '@material-ui/core'
import { Helmet } from 'react-helmet'

const theme = createMuiTheme({
  typography: {
    fontFamily: `Poppins, sans-serif`
  }
})

export default ({ element }) => {
  return (
    <MuiThemeProvider theme={theme}>
      <Helmet>
      <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700&display=swap" rel="stylesheet" />
      </Helmet>
      <React.Fragment>
        <CssBaseline />
        {element}
      </React.Fragment>
    </MuiThemeProvider>
  )
}