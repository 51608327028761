module.exports = [{
      plugin: require('/vercel/workpath0/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-140386411-1"},
    },{
      plugin: require('/vercel/workpath0/node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/vercel/workpath0/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
